import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { useViewportScroll } from "framer-motion"
import ReactMarkDown from "react-markdown"
import { Textfit } from "react-textfit"
import Layout from "../layouts/layout"
import {
  LinkedinShareButton,
  LinkedinIcon,
  EmailIcon,
  EmailShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookIcon,
  FacebookShareButton,
} from "react-share"
// import postStyle from "../templates/blog-post.module.scss"
import postStyle from "./preview.module.scss"

//configure contentful
const contentful = require("contentful")
const client = contentful.createClient({
  space: "11avwnkjhfen",
  accessToken: "hEdqd-B-5BJbRxVmxQ6unwqvfnq1yFd46F-xiPd55C4", //"yhXhjEVIzPI9AYPJkajeeqKYP97Ff_0AT_s5Bw4baWQ",
  host: "preview.contentful.com",
})

// function fetches data and renders preview
export default function Preview({ location, data }) {
  const [preview, setPreview] = useState()
  const { scrollYProgress } = useViewportScroll()
  const [indicator, setindicator] = useState(0)

  const dateOptions = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  async function getPreview() {
    await client.getEntries({ content_type: "blogPost" }).then(posts =>
      posts.items.map(item => {
        item.fields.previewModus && setPreview(item)
      })
    )
  }

  useEffect(() => {
    getPreview()
    scrollYProgress.onChange(x => setindicator(x))
  }, [])

  return (
    <Layout loc={location}>
      {/* {data && console.log(data)} */}

      <div
        style={{
          position: "fixed",
          backgroundColor: "#1f375c",
          width: `${indicator * 100}vw`,
          height: "1rem",
        }}
      ></div>
      {preview && (
        <div className={postStyle.container}>
          {console.log(preview.fields.image.fields.file.url)}
          <div className={postStyle.banner} style = {{backgroundImage: `url(${preview.fields.image.fields.file.url})`}}>
            <div
              className={postStyle.image}
              
            />
            {/* <h1 className={postStyle.title}>{preview.fields.title}</h1> */}
            <Textfit className={postStyle.title} mode="multi" max = {400}>
              {preview.fields.title}<span className = {postStyle.dot}>.</span>
            </Textfit>
            <p className={postStyle.date}>
              {`${new Date(preview.fields.publishDate).toLocaleDateString(
                "nl-NL",
                dateOptions
              )}`}
            </p>
          </div>

          <div className={postStyle.wrapper}>
            <ReactMarkDown className={postStyle.body}>
              {preview.fields.body}
            </ReactMarkDown>

            <LinkedinShareButton
              url={`https://brcg.nl/blog/${preview.fields.slug}`}
            >
              <LinkedinIcon size={32} />
            </LinkedinShareButton>
            <EmailShareButton
              url={`https://brcg.nl/blog/${preview.fields.slug}`}
              subject={`${preview.fields.title} - Business Resilience Consultancy Group`}
            >
              <EmailIcon size={32} />
            </EmailShareButton>
            <TwitterShareButton
              url={`https://brcg.nl/blog/${preview.fields.slug}`}
            >
              <TwitterIcon size={32} />
            </TwitterShareButton>
            <WhatsappShareButton
              url={`https://brcg.nl/blog/${preview.fields.slug}`}
            >
              <WhatsappIcon size={32} />
            </WhatsappShareButton>
            <FacebookShareButton
              url={`https://brcg.nl/blog/${preview.fields.slug}`}
            >
              <FacebookIcon size={32} />
            </FacebookShareButton>
          </div>
        </div>
      )}
    </Layout>
  )
}
